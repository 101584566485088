.experience-suzano_layouts-itemsCarousel{
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.js-itemsCategoryCarousel.skeleton-loaderCarousel .itemsCarouselContainer{
    display: grid;
    overflow: hidden;
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;
    overflow: hidden;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.itemsCarouselContainer{
    margin-left: inherit;
    margin-right: inherit;

    a:hover{
        text-decoration: none;
    }

    .slick-track {
        .slick-slide{
            margin: 0px 6px;
            @media (max-width: 576px) {
                margin: 0px 3px;
            }
        }
    }

    .slick-list.draggable{
        height: 257px;

        @media (max-width: 576px) {
            height: 202px;
        }
    }

}

.itemsHeading {
    display: flex;

    h2 {
        color: #23346c;
        font-size: 23px;
        font-family: "Open Sans" !important;
        font-weight: 700;
        margin-bottom: 24px;

        @media (max-width: 991px) {
            text-align: center;
        }

        @media (max-width: 576px) {
            font-size: 1.2rem;
            margin-bottom: 12px;
        }

    }
}

.experience-suzano_assets-productBoxItem{
    padding-bottom: 2px;
}